export {default as Context} from './Context';
export {default as Provider} from './Provider';

/**
 * como pegar usuário logado
 * 
 * 
  import {Context as AuthContext} from '../../components/stores/Auth';

  const { user } = useContext(AuthContext);
 */
